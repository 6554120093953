import { Analytics } from 'analytics';
import segmentPlugin from '@analytics/segment';

export const TrackEvent = {
  /** Payment Events **/

  // Custom Events
  REDIRECTED_TO_SIGNUP: 'Redirected to /signup',
  PAYMENT_INFO_ENTERED: 'Payment Info Entered',
  ORDER_COMPLETED: 'Order Completed',

  /** Signup Events **/

  // Custom Events
  USER_SIGNED_UP: 'Signed Up',
};

const analytics = Analytics({
  app: 'customer-web',
  plugins: [
    segmentPlugin({
      writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY,
    }),
  ],
});

export default analytics;
