import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const AUTH_TOKEN_COOKIE = 'authToken';
const USER_TOKEN_COOKIE = 'userToken';

export const logout = () => {
  cookies.remove(AUTH_TOKEN_COOKIE, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
  cookies.remove(USER_TOKEN_COOKIE, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
};

// Setup v2 API
export const api = axios.create({
  baseURL: process.env.REACT_APP_CUSTOMER_API_HOST,
  withCredentials: true,
  crossDomain: true,
  responseType: 'json',
});

api.defaults.headers.common['Content-Type'] = 'application/json';

api.interceptors.request.use((config) => {
  const token = cookies.get(AUTH_TOKEN_COOKIE);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
