import React from 'react';
import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import MergeForm from './component';
import { useTheme } from '@mui/material';

const styles = {
  paper: (theme) => ({
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  avatar: (theme) => ({
    backgroundColor: theme.palette.secondary.main,
  }),
};

const GoogleMerge = () => {
  const theme = useTheme();
  return (
    <Container component="main" maxWidth="lg">
      <div style={styles.paper(theme)}>
        <Avatar sx={styles.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Merge Your Account
        </Typography>
        <MergeForm />
      </div>
    </Container>
  );
};

export default GoogleMerge;
