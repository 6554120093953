import { Button, Container } from '@mui/material';
import React, { useEffect } from 'react';
import { useGoogleLogin as useOAuthGoogleLogin } from '@react-oauth/google';
import LoggingManager from '../../logging';
import { ReactComponent as GoogleLogo } from '../../media/google.svg';
import { useGoogleSignUp } from '../../services/api';
import ErrorDialog from '../../ui/ErrorDialog';
import { useAnalytics } from 'use-analytics';
import { TrackEvent } from '../../analytics';
import { useTheme } from '@mui/material/styles';

const logger = new LoggingManager();

const handleGoogleError = (response) => {
  logger.notify(response.error, { context: 'Google Signup' });
};

const GoogleSignUp = () => {
  const theme = useTheme();
  const styles = {
    sso: {
      marginTop: theme.spacing(2),
    },
    ssoLogo: {
      marginRight: theme.spacing(1),
    },
  };

  const { identify, track } = useAnalytics();
  const [googleSignUp, { data, error, isError, isSuccess: isSignedUp }] = useGoogleSignUp();
  const login = useOAuthGoogleLogin({
    onSuccess: (data) => googleSignUp(data),
    onError: (response) => handleGoogleError(response),
    flow: 'auth-code',
  });

  useEffect(() => {
    if (isSignedUp && data?.token) {
      window.analytics.alias(data?.user_id, async () => {
        await identify(data?.user_id, {
          firstName: data?.fname,
          lastName: data?.lname,
          email: data?.email,
        });

        await track(TrackEvent.USER_SIGNED_UP, {
          isSSO: true,
          type: 'google',
        });

        // redirecting the user must happen in the callback to give time for
        // the identify and track events to be called
        window.location.replace(`${process.env.REACT_APP_AURORA_HOST}/accelerate?token=${data?.token}`);
      });
    }
  }, [
    data?.email,
    data?.fname,
    data?.lname,
    data?.team_id,
    data?.token,
    data?.user_id,
    isSignedUp,
    identify,
    track,
  ]);

  return (
    <Container component="main" maxWidth="xs">
      <div style={styles.sso}>
        <Button variant="outlined" style={{ width: '260px' }} onClick={() => login()}>
          <GoogleLogo style={styles.ssoLogo} /> Sign Up with Google
        </Button>
      </div>
      {isError && (
        <ErrorDialog title="Google Sign-up Error" message={error?.data?.message ?? 'Google sign-up failed.'} />
      )}
    </Container>
  );
};

export default GoogleSignUp;
