import { createTheme, responsiveFontSizes } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat, "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: 24,
      fontWeight: 700,
    },
    h2: {
      fontSize: 14,
      fontWeight: 700,
    },
    body1: {
      fontSize: 14,
      fontWeight: 400,
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      main: '#276EB0',
      light: '#6ab8f7',
      alt: '#4CAF50',
    },
    secondary: {
      main: '#4CAF50',
      lighter: '#BBDEFB',
      lightest: '#EFEFEF',
    },
    gradient: {
      main: 'rgba(39, 110, 176, 0.1)',
      alt: 'rgba(76, 175, 80, 0.1)',
    },
    error: {
      main: '#FF5252',
    },
    background: {
      default: '#F3F8Fb',
    },
    disabled: {
      default: '#EFEFEF',
    },
    avatar: {
      main: '#F67270', // color picked from Kate's designs
    },
  },
});

export default responsiveFontSizes(theme);
