import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useAcceptInviteMutation, useGetInvitesQuery } from '../../services/api';
import ErrorDialog from '../../ui/ErrorDialog';

const styles = {
  passwordRequirements: {
    color: 'rgb(106, 106, 106)',
  },
  container: {
    padding: (theme) => theme.spacing(8, 0),
  },
};

const defaultFormValues = {
  password: '',
};

const formSchema = yup.object({
  password: yup
    .string()
    .ensure()
    .trim()
    .required('Password is a required field')
    .min(10, ({ min }) => `Password must be at least ${min} characters long`),
});

const AcceptInvite = () => {
  const { token } = useParams();
  const [email, setEmail] = useState('');
  const [user, setUser] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [acceptInvite, { isLoading: isAccepting }] = useAcceptInviteMutation();

  const {
    handleSubmit,
    control,
    formState: { isValid, submitCount },
  } = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(formSchema),
  });

  const { data: tokenInfo, isLoading, isError, isSuccess } = useGetInvitesQuery(token);

  useEffect(() => {
    if (isSuccess) {
      setEmail(tokenInfo?.token?.user?.emailAddress ?? '');
      setUser(tokenInfo?.token?.user ?? {});
    }
  }, [isSuccess, tokenInfo?.token?.user]);

  useEffect(() => {
    if (isError) {
      setErrorMessage('Sorry, it looks like this token is expired.');
      setShowErrorDialog(true);
    }
  }, [isError]);

  function onSubmit(data) {
    const { password } = data;
    acceptInvite({ token, password })
      .unwrap()
      .then((res) => {
        window.location.replace(`${process.env.REACT_APP_AURORA_HOST}/accelerate?token=${res.token}`);
      })
      .catch((err) => {
        setErrorMessage('Sorry, something went wrong');
        setShowErrorDialog(true);
      });
  }

  const handleClickShowPassword = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const closeDialogCallback = () => {
    setErrorMessage('');
  };

  if (isLoading) {
    return (
      <Container maxWidth="xs" sx={styles.container}>
        <Typography component="h1" variant="h1" paragraph>
          Accept Invite
        </Typography>
        <Box width="100%" height={350} p={2} border="thin solid lightgrey">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <>
      <Container maxWidth="xs" sx={styles.container}>
        <Typography component="h1" variant="h1" paragraph>
          Accept Invite
        </Typography>
        <Box width="100%" p={2} border="thin solid lightgrey">
          <Typography variant="h6">Hi, {user?.firstName}</Typography>
          <Typography component="div" variant="caption" paragraph>
            Create a password to get started
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <TextField
                  type="email"
                  name="email"
                  variant="outlined"
                  value={email}
                  disabled
                  required
                  fullWidth
                  label="Email Address"
                />
              </Grid>
              <Grid item>
                <Controller
                  control={control}
                  name="password"
                  render={({ field: { ref, ...fieldProps }, fieldState: { error, invalid } }) => (
                    <TextField
                      inputRef={ref}
                      {...fieldProps}
                      variant="outlined"
                      required
                      fullWidth
                      label="Password"
                      type={showPassword ? 'text' : 'password'}
                      autoComplete="new-password"
                      error={invalid}
                      helperText={error?.message ?? ''}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}>
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Tooltip
                arrow
                title="A minimum of 10 characters long. Can include letters, numbers, and special characters"
                placement="right-end">
                <Grid container item spacing={1} justifyContent="flex-end">
                  <Grid item>
                    <Typography variant="caption">Password Requirements</Typography>
                  </Grid>
                  <Grid item>
                    <InfoIcon sx={styles.passwordRequirements} fontSize="small" />
                  </Grid>
                </Grid>
              </Tooltip>

              <Grid item>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isAccepting || (!isValid && submitCount > 0)}>
                  {isAccepting ? 'Accepting Invite...' : 'Accept Invite'}
                </Button>
              </Grid>
              <Grid item>
                <Typography variant="caption">
                  If you are having trouble, please{' '}
                  <a href="mailto:support@wevo.ai?Subject=Login%20issues">email support</a>.
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
      {showErrorDialog && <ErrorDialog message={errorMessage} callback={closeDialogCallback} />}
    </>
  );
};

export default AcceptInvite;
