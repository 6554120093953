import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useResetPassword } from '../../services/api';
import ErrorDialog from '../../ui/ErrorDialog';
import { Link } from '@mui/material';

const defaultFormValues = {
  password: '',
};

const formSchema = yup.object({
  password: yup
    .string()
    .ensure()
    .trim()
    .required('Password is a required field')
    .min(10, ({ min }) => `Password must be at least ${min} characters long`),
});

const styles = {
  submit: (theme) => ({
    margin: theme.spacing(2, 0, 2),
  }),
  buttonSuccess: (theme) => ({
    margin: theme.spacing(2, 0, 2),
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  }),
  buttonProgress: (theme) => ({
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }),
  link: (theme) => ({
    textDecoration: 'none',
    color: theme.palette.primary.main,
  }),
  supportButton: (theme) => ({
    textTransform: 'none',
  }),
};

const ResetPasswordForm = ({ resetToken }) => {
  const [showPassword, setShowPassword] = useState(false);

  const [resetPassword, { data, error, isLoading, isError, isSuccess }] = useResetPassword();

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(formSchema),
  });

  const onSubmit = (data) => {
    resetPassword({ password: data?.password, resetToken });
  };

  const handleClickShowPassword = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  if (isSuccess) {
    window.location.replace(`${process.env.REACT_APP_AURORA_HOST}/accelerate?token=${data?.token}`);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="password"
            render={({ field: { ref, ...fieldProps }, fieldState: { error, invalid } }) => (
              <TextField
                inputRef={ref}
                {...fieldProps}
                variant="outlined"
                required
                fullWidth
                label="Password"
                type={showPassword ? 'text' : 'password'}
                autoComplete="new-password"
                error={invalid}
                helperText={error?.message ?? ''}
                disabled={isLoading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        sx={isSuccess ? styles.buttonSuccess : styles.submit}
        disabled={!isValid || isLoading}>
        {isSuccess ? 'Password Reset Successfully' : 'Reset Password'}
      </Button>
      {isLoading && <CircularProgress size={24} sx={styles.buttonProgress} />}
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Link href="/login" underline="hover">
            Go to login page
          </Link>
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end">
        <Grid item className="drift-open-chat">
          {/* this button opens a drift conversation */}
          <Button color="primary" size="small" sx={{ textTransform: 'none' }}>
            Chat with support
          </Button>
        </Grid>
      </Grid>
      {isError && (
        <ErrorDialog
          title="Password Reset Error"
          message={error?.data?.humanReadableMessage ?? 'Password Reset Failed'}
        />
      )}
    </form>
  );
};

export default ResetPasswordForm;
