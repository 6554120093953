import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';

const styles = {
  global: {
    html: {
      height: '100%',
    },
    body: {
      height: '100%',
    },
  },
  root: (theme) => ({
    marginTop: theme.spacing(8),
  }),
  instructions: (theme) => ({
    margin: theme.spacing(2, 0, 0, 0),
  }),
  divider: (theme) => ({
    margin: theme.spacing(3, 0),
  }),
  progress: (theme) => ({
    margin: theme.spacing(0, 0, 3, 0),
  }),
};

const MergeInterstitial = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = location.state || {};

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(`${process.env.REACT_APP_AURORA_HOST}/accelerate?token=${token}`);
    }, 8000);

    return () => clearTimeout(timer);
  }, [navigate, token]);

  return (
    <Container component="main" maxWidth="xs" sx={styles.root(theme)}>
      <Typography variant="h5">Merge Successful!</Typography>
      <Typography sx={styles.instructions(theme)} variant="subtitle1">
        You have successfully merged your WEVO account with your Google account. From now on please login using
        the "Login with Google" button on the login page. Your old email and password combination will no
        longer be able to be used.
      </Typography>
      <Divider sx={styles.divider(theme)} />
      <CircularProgress sx={styles.progress(theme)} color="secondary" />
      <Typography variant="subtitle2">You will be redirected to your account momentarily.</Typography>
    </Container>
  );
};

export default MergeInterstitial;
