import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { Alert, Box, Button, Container, Divider, Grid, Link, Typography } from '@mui/material';
import queryString from 'query-string';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import GoogleSignIn from '../GoogleSignIn/index';
import MicrosoftSignIn from '../MicrosoftSignIn/index';
import LoginForm from './component';

const OAuthDivider = () => {
  return (
    <Box width={'100%'}>
      <Divider sx={{ color: 'gray' }}>or</Divider>
    </Box>
  );
};

const styles = {
  paper: (theme) => ({
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  avatar: (theme) => ({
    margin: theme.spacing(1),
    backgroundColor: theme.palette.avatar.main,
  }),
  form: (theme) => ({
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  }),
  submit: (theme) => ({
    margin: theme.spacing(3, 0, 2),
  }),
  ssoLogo: (theme) => ({
    marginRight: theme.spacing(1),
  }),
  supportButton: {
    textTransform: 'none',
    padding: '0px',
  },
};

const Login = () => {
  const navigate = useNavigate();

  const { search } = useLocation();
  const queryParams = queryString.parse(search);
  const message = queryParams?.message && decodeURIComponent(queryParams?.message);

  return (
    <>
      {message && (
        <Grid container justifyContent="center">
          <Grid item sx={{ marginBottom: 2, justifyItems: 'center', width: '100%' }}>
            <Alert severity="error" color="error" sx={{ justifyContent: 'center' }}>
              <Box sx={{ fontSize: '16px', justifyItems: 'center' }}>{message}</Box>
            </Alert>
          </Grid>
        </Grid>
      )}
      <Container component="main" maxWidth="sm">
        <Box sx={styles.paper}>
          <Typography component="h1" variant="h1">
            Welcome Back
          </Typography>
          <LoginForm />

          <OAuthDivider />

          <GoogleSignIn />
          <MicrosoftSignIn />

          {/* Log in with SSO */}
          <Box pt={2}>
            <Button
              variant="outlined"
              sx={{ width: '250px', color: 'black' }}
              onClick={() => navigate('/login/sso')}>
              <VpnKeyIcon sx={styles.ssoLogo} />
              Log In with SSO
            </Button>
          </Box>

          <Grid container sx={{ pt: 3, pb: 5 }} justifyContent="center">
            <Grid item>
              <Typography variant="body1" display="inline">
                Don't have an account? &nbsp;
              </Typography>
              <Link href="https://wevo.ai/takeapulse" variant="body2" underline="hover">
                Sign Up
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default Login;
