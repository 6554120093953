import Bugsnag from '@bugsnag/js';

class LoggingManager {
  notify(err, options = {}) {
    const { context, user, ...metaData } = options;
    Bugsnag.notify(err, { context, user, metaData });

    if (process.env.NODE_ENV !== 'production') {
      console.log(err);
    }
  }
}

export default LoggingManager;
