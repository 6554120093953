export const SET_INCOMING_PLAN_ID = 'SET_INCOMING_PLAN_ID';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const GOOGLE_LOGIN = 'GOOGLE_LOGIN';
export const GOOGLE_LOGIN_SUCCESS = 'GOOGLE_LOGIN_SUCCESS';
export const GOOGLE_LOGIN_FAILED = 'GOOGLE_LOGIN_FAILED';

export const MICROSOFT_LOGIN = 'MICROSOFT_LOGIN';
export const MICROSOFT_LOGIN_SUCCESS = 'MICROSOFT_LOGIN_SUCCESS';
export const MICROSOFT_LOGIN_FAILED = 'MICROSOFT_LOGIN_FAILED';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

export const SIGN_UP = 'SIGN_UP';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILED = 'SIGN_UP_FAILED';

export const GOOGLE_SIGN_UP = 'GOOGLE_SIGN_UP';
export const GOOGLE_SIGN_UP_SUCCESS = 'GOOGLE_SIGN_UP_SUCCESS';
export const GOOGLE_SIGN_UP_FAILED = 'GOOGLE_SIGN_UP_FAILED';

export const MICROSOFT_SIGN_UP = 'MICROSOFT_SIGN_UP';
export const MICROSOFT_SIGN_UP_SUCCESS = 'MICROSOFT_SIGN_UP_SUCCESS';
export const MICROSOFT_SIGN_UP_FAILED = 'MICROSOFT_SIGN_UP_FAILED';

export const GOOGLE_MERGE = 'GOOGLE_MERGE';
export const GOOGLE_MERGE_SUCCESS = 'GOOGLE_MERGE_SUCCESS';
export const GOOGLE_MERGE_FAILED = 'GOOGLE_MERGE_FAILED';

export const REQUEST = 'REQUEST';
export const REQUEST_START = 'REQUEST_START';
export const REQUEST_COMPLETE = 'REQUEST_COMPLETE';
export const ACCESS_DENIED = 'ACCESS_DENIED';
export const API_ERROR = 'API_ERROR';
export const DISMISS_ERROR = 'DISMISS_ERROR';

export const SET_STEPPER = 'SET_STEPPER';
