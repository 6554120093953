import { combineReducers } from 'redux';

import user from './user';
import request from './request';
import { api } from '../services/api';

export default combineReducers({
  request,
  user,
  [api.reducerPath]: api.reducer,
});
