import { Grid, Link } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import qs from 'qs';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import GoogleSignUp from '../GoogleSignUp/index';
import MicrosoftSignUp from '../MicrosoftSignUp';
import SignUpForm from './component';

const styles = {
  oAuthDivider: (theme) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    '& .MuiDivider-root': {
      flexGrow: 1,
    },
    '& .MuiTypography-root': {
      margin: theme.spacing(0, 2),
    },
  }),
  loginContainer: (theme) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(5),
    justifyContent: 'center',
  }),
};

const OAuthDivider = () => {
  return (
    <Box sx={styles.oAuthDivider}>
      <Divider component="p"></Divider>
      <Typography variant="body1" component="span">
        or
      </Typography>
      <Divider component="p"></Divider>
    </Box>
  );
};

const SignUp = () => {
  const location = useLocation();

  useEffect(() => {
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

    const subscriptionLevel = queryParams.subscription;
    if (subscriptionLevel) {
      sessionStorage.setItem('subscriptionLevel', subscriptionLevel);
    }

    const subscriptionTerm = queryParams.term;
    if (subscriptionTerm) {
      sessionStorage.setItem('subscriptionTerm', subscriptionTerm);
    }
  }, [location.search]);

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div>
        <Typography component="h1" variant="h1">
          Uncover What Customers Think
        </Typography>
        <SignUpForm />
        <OAuthDivider />
        <GoogleSignUp />
        <MicrosoftSignUp />

        <Grid container sx={styles.loginContainer}>
          <Grid item>
            <Typography variant="body1" display="inline">
              Already have an account?&nbsp;
            </Typography>
            <Link href="/login" variant="body2" underline="hover">
              Log In
            </Link>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default SignUp;
