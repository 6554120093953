import { Box, Typography, Button, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import React, { useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ErrorDialog from '../../ui/ErrorDialog';
import { useSignUp } from '../../services/api';
import { useAnalytics } from 'use-analytics';
import { TrackEvent } from '../../analytics';

const styles = {
  container: (theme) => ({
    textAlign: 'left',
  }),
  titleText: (theme) => ({
    textAlign: 'left',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  }),
  passwordInfo: (theme) => ({
    paddingLeft: '2px',
  }),
  form: (theme) => ({
    // Add styles for form if needed
  }),
  submit: (theme) => ({
    // Add styles for submit button if needed
  }),
  supportButton: (theme) => ({
    // Add styles for support button if needed
  }),
};

const defaultFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
};

const formSchema = yup.object({
  firstName: yup.string().ensure().trim().required('First name is required'),
  lastName: yup.string().ensure().trim().required('Last name is required'),
  email: yup.string().ensure().email('Invalid email address').required('Email address is required'),
  password: yup
    .string()
    .ensure()
    .trim()
    .required('Password is a required field')
    .min(10, ({ min }) => `Password must be at least ${min} characters long`),
});

const SignUpForm = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const [signUp, { data, error, isError, isLoading, isSuccess: isSignedUp }] = useSignUp();

  const { identify, track } = useAnalytics();

  const {
    handleSubmit,
    control,
    formState: { isValid, submitCount },
  } = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(formSchema),
  });

  const firstName = useWatch({ control, name: 'firstName' });
  const lastName = useWatch({ control, name: 'lastName' });
  const email = useWatch({ control, name: 'email' });

  useEffect(() => {
    if (isSignedUp && data?.token) {
      window.analytics.alias(data?.user_id, async () => {
        await identify(data?.user_id, {
          firstName,
          lastName,
          email,
        });

        await track(TrackEvent.USER_SIGNED_UP, { type: 'password' });

        // redirecting the user must happen in the callback to give time for
        // the identify and track events to be called
        window.location.replace(`${process.env.REACT_APP_AURORA_HOST}/accelerate?token=${data?.token}`);
      });
    }
  }, [data?.team_id, data?.token, data?.user_id, email, firstName, isSignedUp, lastName, identify, track]);

  const onSubmit = (data) => {
    signUp(data);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (ev) => {
    ev.preventDefault();
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <Box sx={styles.titleText}>
        <Typography variant="h2">Create An Account</Typography>
      </Box>
      <Grid container spacing={2} sx={styles.container}>
        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="firstName"
            render={({ field: { ref, ...fieldProps }, fieldState: { invalid, error } }) => (
              <TextField
                {...fieldProps}
                inputRef={ref}
                autoComplete="fname"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                helperText={error?.message ?? ''}
                error={invalid}
                disabled={isLoading}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="lastName"
            render={({ field: { ref, ...fieldProps }, fieldState: { invalid, error } }) => (
              <TextField
                {...fieldProps}
                inputRef={ref}
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                autoComplete="family-name"
                helperText={error?.message ?? ''}
                error={invalid}
                disabled={isLoading}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="email"
            render={({ field: { ref, ...fieldProps }, fieldState: { invalid, error } }) => (
              <TextField
                {...fieldProps}
                inputRef={ref}
                variant="outlined"
                type="email"
                required
                fullWidth
                id="email"
                label="Email Address"
                autoComplete="email"
                helperText={error?.message ?? ''}
                error={invalid}
                disabled={isLoading}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="password"
            render={({ field: { ref, ...fieldProps }, fieldState: { invalid, error } }) => (
              <TextField
                {...fieldProps}
                inputRef={ref}
                variant="outlined"
                required
                fullWidth
                label="Password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                helperText={error?.message ?? ''}
                error={invalid}
                disabled={isLoading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <Typography sx={styles.passwordInfo} variant="caption">
            Must be at least 10 characters
          </Typography>
        </Grid>
      </Grid>

      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        sx={styles.submit}
        disabled={isLoading || (!isValid && submitCount > 0)}>
        {isLoading ? 'Creating Account...' : 'Create Account'}
      </Button>
      <Grid container justifyContent="flex-end">
        <Grid item className="drift-open-chat">
          {/* this button opens a drift conversation */}
          <Button color="primary" size="small" style={{ textTransform: 'none' }}>
            Chat with support
          </Button>
        </Grid>
      </Grid>
      {isError && <ErrorDialog title="Sign-up Error" message={error?.data?.message ?? ''} />}
    </form>
  );
};

export default SignUpForm;
