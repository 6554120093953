import { Box, CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import { api } from '../../api';
import LoggingManager from '../../logging';
import { useTheme } from '@mui/material/styles';
import { useSearchParams } from 'react-router-dom';

const logger = new LoggingManager();

async function authorize(queryParams) {
  try {
    const { data } = await api({
      method: 'GET',
      url: `${process.env.REACT_APP_CUSTOMER_API_HOST}/sso/authorize?${queryParams}`,
      withCredentials: true,
    });

    const redirectUrl = `${data?.redirectUri}?state=${data?.state}&code=${data?.code}`;
    window.location.href = redirectUrl;
  } catch (err) {
    logger.notify(err, { context: 'OAuth Authorize' });
  }
}

/**
 * Called by Userlytics. Makes a call to v2 API /sso/authorize endpoint to generate an OAuth code
 * and redirects back to Userlytics' redirect_uri.
 */
const OAuthAuthorize = () => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const queryParams = searchParams.toString();

  useEffect(() => {
    authorize(queryParams);
  }, [queryParams]);

  const styles = {
    box: (theme) => ({
      padding: theme.spacing(2),
    }),
  };

  return (
    <Box sx={styles.box(theme)}>
      <CircularProgress />
    </Box>
  );
};

export default OAuthAuthorize;
