import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useGoogleLogin as useOAuthGoogleLogin } from '@react-oauth/google';
import { ReactComponent as GoogleLogo } from '../../media/google.svg';
import { useGoogleMerge, useLogin } from '../../services/api';

import { Box, Step, StepLabel, Stepper, useTheme } from '@mui/material';
import StepConnector from '@mui/material/StepConnector';
import { useNavigate } from 'react-router-dom';
import LoggingManager from '../../logging';
import ErrorDialog from '../../ui/ErrorDialog';

const logger = new LoggingManager();

const MERGE_STEPPER_STEPS = ['Login', 'Choose Google Account', 'Account Dashboard'];

const styles = {
  mergeStepper: (theme) => ({
    marginTop: theme.spacing(3),
  }),
  form: (theme) => ({
    marginTop: theme.spacing(1),
  }),
  submit: (theme) => ({
    margin: theme.spacing(3, 0, 2),
  }),
};

const MergeForm = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [disableInput, setDisableInput] = useState(false);
  const [stepper, setStepper] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authToken, setAuthToken] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const [login] = useLogin();
  const [googleMerge] = useGoogleMerge();

  const googleLogin = useOAuthGoogleLogin({
    onSuccess: (data) => handleGoogleSuccess(data),
    onError: (response) => handleGoogleError(response),
    flow: 'auth-code',
  });

  const onSubmit = (ev) => {
    ev.preventDefault();
    const formData = { email, password };
    setDisableInput(true);
    login(formData)
      .unwrap()
      .then((res) => {
        setAuthToken(res?.token);
        setIsLoggedIn(true);
        setStepper(1);
      })
      .catch((err) => {
        logger.notify(err?.data?.humanReadableMessage, { context: 'Google Merge' });
        setErrorMessage(err?.data?.humanReadableMessage);
      });
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (ev) => {
    ev.preventDefault();
  };

  const handleGoogleSuccess = (data) => {
    googleMerge(data)
      .unwrap()
      .then(() => {
        setStepper(2);
        navigate('/merge/success', { state: { token: authToken } });
      })
      .catch((err) => {
        logger.notify(err?.data?.humanReadableMessage, { context: 'Google Merge' });
        setErrorMessage(err?.data?.humanReadableMessage);
      });
  };

  const handleGoogleError = (response) => {
    logger.notify(response.error, { context: 'Google Merge' });
    setErrorMessage(response.error);
  };

  const setInputsToEnabled = () => {
    setDisableInput(false);
    setErrorMessage();
  };

  return (
    <>
      <Stepper sx={styles.mergeStepper} activeStep={stepper} alternativeLabel connector={<StepConnector />}>
        {MERGE_STEPPER_STEPS.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box py={3}>
        <form style={styles.form(theme)} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="filled"
                required
                fullWidth
                id="email"
                label="Email Address"
                onChange={(ev) => setEmail(ev.target.value)}
                name="email"
                autoComplete="email"
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={disableInput}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="filled"
                required
                fullWidth
                onChange={(ev) => setPassword(ev.target.value)}
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                disabled={disableInput}
              />
            </Grid>
          </Grid>
          {!isLoggedIn ? (
            <Box py={2}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={styles.submit}
                onClick={onSubmit}
                disabled={disableInput}>
                {disableInput ? 'logging in...' : 'Login'}
              </Button>
            </Box>
          ) : (
            <Box py={2}>
              <Button variant="outlined" sx={{ width: '250px' }} onClick={() => googleLogin()}>
                <GoogleLogo style={{ marginRight: '8px' }} /> Log In with Google
              </Button>
            </Box>
          )}
          <Box py={2}>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/forgot-password" variant="body2" underline="hover">
                  Forgot your password?
                </Link>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/login" variant="body2" underline="hover">
                  Back to login &#9656;
                </Link>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item className="drift-open-chat">
                {/* this button opens a drift conversation */}
                <Button color="primary" size="small" sx={{ textTransform: 'none' }}>
                  Chat with support
                </Button>
              </Grid>
            </Grid>
            {errorMessage && (
              <ErrorDialog
                title="Account Merging Error"
                message={errorMessage}
                callback={setInputsToEnabled}
              />
            )}
          </Box>
        </form>
      </Box>
    </>
  );
};

export default MergeForm;
