import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import analytics from './analytics';

function AppContainer({ children }) {
  const location = useLocation();

  useEffect(() => {
    analytics.page({
      name: location.pathname,
      ...location,
    });
  }, [location]);

  return children;
}

export default AppContainer;
