import React from 'react';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ForgotPasswordForm from './component';

import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

const styles = {
  paper: (theme) => ({
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  avatar: (theme) => ({
    backgroundColor: '#F67270',
  }),
  resetPasswordInstructions: (theme) => ({
    marginTop: theme.spacing(1),
  }),
};

const ForgotPassword = (props) => {
  const theme = useTheme();

  return (
    <Container component="main" maxWidth="sm">
      <div style={styles.paper(theme)}>
        <Avatar sx={styles.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Grid container sx={{ justifyContent: 'center' }}>
          <Grid item>
            <Box py={1}>
              <Typography component="h1" variant="h5">
                Forgot Your Password?
              </Typography>
              <Typography component="p">These things happen. Now let's fix that.</Typography>
            </Box>
          </Grid>
        </Grid>
        <ForgotPasswordForm />
      </div>
    </Container>
  );
};

export default ForgotPassword;
