import _get from 'lodash/get';

import {
  REQUEST,
  SIGN_UP,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILED,
  GOOGLE_SIGN_UP,
  GOOGLE_SIGN_UP_SUCCESS,
  GOOGLE_SIGN_UP_FAILED,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILED,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  GOOGLE_LOGIN,
  GOOGLE_LOGIN_SUCCESS,
  GOOGLE_LOGIN_FAILED,
  GOOGLE_MERGE,
  GOOGLE_MERGE_SUCCESS,
  GOOGLE_MERGE_FAILED,
  MICROSOFT_LOGIN,
  MICROSOFT_LOGIN_SUCCESS,
  MICROSOFT_LOGIN_FAILED,
  MICROSOFT_SIGN_UP,
  MICROSOFT_SIGN_UP_SUCCESS,
  MICROSOFT_SIGN_UP_FAILED,
  SET_INCOMING_PLAN_ID,
  SET_STEPPER,
  DISMISS_ERROR,
} from './types';

export function setStepper(step) {
  return {
    type: SET_STEPPER,
    payload: step,
  };
}

export function setIncomingPlanId(incomingPlanId) {
  return {
    type: SET_INCOMING_PLAN_ID,
    payload: incomingPlanId,
  };
}

export function login({ password, email }) {
  const data = {
    password,
    email_address: email,
  };
  return apiAction({
    url: `${process.env.REACT_APP_CUSTOMER_API_HOST}/api/v2/users/login`,
    method: 'POST',
    data,
    onSuccess: loginSuccess,
    onFailure: loginFailed,
    label: LOGIN,
  });
}

function loginSuccess(data) {
  return {
    type: LOGIN_SUCCESS,
    payload: data,
  };
}

function loginFailed(res) {
  return {
    type: LOGIN_FAILED,
    payload: { error: _get(res, 'data.humanReadableMessage', 'Login Failed'), success: false },
  };
}

export function forgotPassword({ email }) {
  const data = { email_address: email };
  return apiAction({
    url: `${process.env.REACT_APP_CUSTOMER_API_HOST}/api/v2/password/forgot`,
    method: 'POST',
    data,
    onSuccess: forgotPasswordSuccess,
    onFailure: forgotPasswordFailed,
    label: FORGOT_PASSWORD,
  });
}

function forgotPasswordSuccess(data) {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    payload: data,
  };
}

function forgotPasswordFailed(res) {
  return {
    type: FORGOT_PASSWORD_FAILED,
    payload: {
      error: _get(res, 'data.humanReadableMessage', 'Password Reset Request Failed'),
      success: false,
    },
  };
}

export function resetPassword({ email, password, resetToken }) {
  const data = { email_address: email, password, resetToken };
  return apiAction({
    url: `${process.env.REACT_APP_CUSTOMER_API_HOST}/api/v2/password/reset`,
    method: 'POST',
    data,
    onSuccess: resetPasswordSuccess,
    onFailure: resetPasswordFailed,
    label: RESET_PASSWORD,
  });
}

function resetPasswordSuccess(data) {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: data,
  };
}

function resetPasswordFailed(res) {
  return {
    type: RESET_PASSWORD_FAILED,
    payload: { error: _get(res, 'data.humanReadableMessage', 'Password Reset Failed'), success: false },
  };
}

export function signUp({ password, email, fname, lname }) {
  const subscriptionLevel = sessionStorage.getItem('subscriptionLevel');
  const subscriptionTerm = sessionStorage.getItem('subscriptionTerm');

  // map to API schema
  const data = {
    password,
    email_address: email,
    first_name: fname,
    last_name: lname,
    subscription_level: subscriptionLevel,
    subscription_term: subscriptionTerm,
  };
  return apiAction({
    url: `${process.env.REACT_APP_CUSTOMER_API_HOST}/api/v2/users/signup`,
    method: 'POST',
    data,
    onSuccess: (data) => signUpSuccess({ ...data, email, fname, lname }),
    onFailure: signUpFailed,
    label: SIGN_UP,
  });
}

function signUpSuccess(data) {
  return {
    type: SIGN_UP_SUCCESS,
    payload: data,
  };
}

function signUpFailed(res) {
  return {
    type: SIGN_UP_FAILED,
    payload: {
      error: _get(
        res,
        'data.humanReadableMessage',
        'A server error has occurred. Sorry about that! Our tech team has been notified'
      ),
      success: false,
    },
  };
}

export function googleSignUp(res) {
  const subscriptionLevel = sessionStorage.getItem('subscriptionLevel');
  const subscriptionTerm = sessionStorage.getItem('subscriptionTerm');

  // the `res` here is the response from the Google OAuth request, not our API
  const data = {
    code: res.code,
    subscription_level: subscriptionLevel,
    subscription_term: subscriptionTerm,
  };
  return apiAction({
    url: `${process.env.REACT_APP_CUSTOMER_API_HOST}/api/v2/google/signup`,
    method: 'POST',
    data,
    onSuccess: googleSignUpSuccess,
    onFailure: googleSignUpFailed,
    label: GOOGLE_SIGN_UP,
  });
}

function googleSignUpSuccess(res) {
  return {
    type: GOOGLE_SIGN_UP_SUCCESS,
    payload: res,
  };
}

function googleSignUpFailed(res) {
  return {
    type: GOOGLE_SIGN_UP_FAILED,
    payload: {
      error: _get(
        res,
        'data.humanReadableMessage',
        "Oops, something didn't go right. Sorry about that! The tech team has just been notified, and they'll fix the problem ASAP. If you start a chat with us, we'll let you know when the problem is fixed."
      ),
      success: false,
    },
  };
}

export function googleLogin(res) {
  // the `res` here is the response from the Google OAuth request, not our API
  const data = {
    code: res.code,
  };
  return apiAction({
    url: `${process.env.REACT_APP_CUSTOMER_API_HOST}/api/v2/google/login`,
    method: 'POST',
    data,
    onSuccess: googleLoginSuccess,
    onFailure: googleLoginFailed,
    label: GOOGLE_LOGIN,
  });
}

function googleLoginSuccess(data) {
  return {
    type: GOOGLE_LOGIN_SUCCESS,
    payload: data,
  };
}

function googleLoginFailed(res) {
  return {
    type: GOOGLE_LOGIN_FAILED,
    payload: {
      error: _get(res, 'data.humanReadableMessage', 'Login Failed'),
      success: false,
      showMergeOffer: _get(res, 'data.showMergeOffer', false),
    },
  };
}

export function googleMerge(res) {
  // the `res` here is the response from the Google OAuth request, not our API
  const data = {
    code: res.code,
  };
  return apiAction({
    url: `${process.env.REACT_APP_CUSTOMER_API_HOST}/api/v2/google/merge`,
    method: 'POST',
    data,
    onSuccess: googleMergeSuccess,
    onFailure: googleMergeFailed,
    label: GOOGLE_MERGE,
  });
}

function googleMergeSuccess(data) {
  return {
    type: GOOGLE_MERGE_SUCCESS,
    payload: data,
  };
}

function googleMergeFailed(res) {
  return {
    type: GOOGLE_MERGE_FAILED,
    payload: { error: _get(res, 'data.humanReadableMessage', 'Login Failed'), success: false },
  };
}

export function microsoftSignUp(res) {
  const subscriptionLevel = sessionStorage.getItem('subscriptionLevel');
  const subscriptionTerm = sessionStorage.getItem('subscriptionTerm');

  // the `res` here is the response from the Microsoft OAuth request, not our API
  const data = {
    code: res.accessToken,
    subscription_level: subscriptionLevel,
    subscription_term: subscriptionTerm,
  };
  return apiAction({
    url: `${process.env.REACT_APP_CUSTOMER_API_HOST}/api/v2/microsoft/signup`,
    method: 'POST',
    data,
    onSuccess: microsoftSignUpSuccess,
    onFailure: microsoftSignUpFailed,
    label: MICROSOFT_SIGN_UP,
  });
}

function microsoftSignUpSuccess(res) {
  return {
    type: MICROSOFT_SIGN_UP_SUCCESS,
    payload: res,
  };
}

function microsoftSignUpFailed(res) {
  return {
    type: MICROSOFT_SIGN_UP_FAILED,
    payload: {
      error: _get(
        res,
        'data.humanReadableMessage',
        "Oops, something didn't go right. Sorry about that! The tech team has just been notified, and they'll fix the problem ASAP. If you start a chat with us, we'll let you know when the problem is fixed."
      ),
      success: false,
    },
  };
}

export function microsoftLogin(res) {
  // the `res` here is the response from the Microsoft OAuth request, not our API
  const data = {
    code: res.accessToken,
  };
  return apiAction({
    url: `${process.env.REACT_APP_CUSTOMER_API_HOST}/api/v2/microsoft/login`,
    method: 'POST',
    data,
    onSuccess: microsoftLoginSuccess,
    onFailure: microsoftLoginFailed,
    label: MICROSOFT_LOGIN,
  });
}

function microsoftLoginSuccess(data) {
  return {
    type: MICROSOFT_LOGIN_SUCCESS,
    payload: data,
  };
}

function microsoftLoginFailed(res) {
  return {
    type: MICROSOFT_LOGIN_FAILED,
    payload: {
      error: _get(res, 'data.humanReadableMessage', 'Login Failed'),
      success: false,
      showMergeOffer: _get(res, 'data.showMergeOffer', false),
    },
  };
}

export function dismissError() {
  return {
    type: DISMISS_ERROR,
    payload: { error: '' },
  };
}

function apiAction({
  url = '',
  method = 'GET',
  data = null,
  accessToken = null,
  onSuccess = () => {},
  onFailure = () => {},
  label = '',
  headersOverride = null,
}) {
  return {
    type: REQUEST,
    payload: {
      url,
      method,
      data,
      accessToken,
      onSuccess,
      onFailure,
      label,
      headersOverride,
    },
  };
}
