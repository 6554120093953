import {
  DISMISS_ERROR,
  FORGOT_PASSWORD_FAILED,
  FORGOT_PASSWORD_SUCCESS,
  GOOGLE_LOGIN_FAILED,
  GOOGLE_LOGIN_SUCCESS,
  GOOGLE_MERGE_FAILED,
  GOOGLE_MERGE_SUCCESS,
  GOOGLE_SIGN_UP_FAILED,
  GOOGLE_SIGN_UP_SUCCESS,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  MICROSOFT_LOGIN_FAILED,
  MICROSOFT_LOGIN_SUCCESS,
  MICROSOFT_SIGN_UP_FAILED,
  MICROSOFT_SIGN_UP_SUCCESS,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_SUCCESS,
  SIGN_UP_FAILED,
  SIGN_UP_SUCCESS,
} from '../actions/types';

const defaultState = {
  isLoggedIn: false,
  isSignedUp: false,
  isAccountMerged: false,
  showMergeOffer: false,
};

const defaultAction = {
  payload: {},
};

export default function userReducer(state = defaultState, action) {
  const { token, email, fname, lname, user_id, team_id, error, showMergeOffer } =
    action.payload || defaultAction.payload;
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        token,
        errorMessage: '',
      };
    case LOGIN_FAILED:
      return {
        ...state,
        isLoggedIn: false,
        errorMessage: error,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        resetEmailSent: true,
        errorMessage: '',
      };
    case FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        resetEmailSent: false,
        errorMessage: error,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        token,
        isPasswordReset: true,
        errorMessage: '',
      };
    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        isPasswordReset: false,
        errorMessage: error,
      };
    case GOOGLE_LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        token,
        errorMessage: '',
      };
    case GOOGLE_LOGIN_FAILED:
      return {
        ...state,
        isLoggedIn: false,
        errorMessage: error,
        showMergeOffer,
      };
    case MICROSOFT_LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        token,
        errorMessage: '',
      };
    case MICROSOFT_LOGIN_FAILED:
      return {
        ...state,
        isLoggedIn: false,
        errorMessage: error,
        showMergeOffer,
      };
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        token,
        email,
        name: `${fname} ${lname}`,
        isSignedUp: true,
        user_id,
        team_id,
        errorMessage: '',
      };
    case SIGN_UP_FAILED:
      return {
        ...state,
        isSignedUp: false,
        errorMessage: error,
      };
    case GOOGLE_SIGN_UP_SUCCESS:
      return {
        ...state,
        token,
        email,
        fname,
        lname,
        name: `${fname} ${lname}`,
        isSignedUp: true,
        user_id,
        team_id,
        errorMessage: '',
      };
    case GOOGLE_SIGN_UP_FAILED:
      return {
        ...state,
        isSignedUp: false,
        errorMessage: error,
      };
    case MICROSOFT_SIGN_UP_SUCCESS:
      return {
        ...state,
        token,
        email,
        fname,
        lname,
        name: `${fname} ${lname}`,
        isSignedUp: true,
        user_id,
        team_id,
        errorMessage: '',
      };
    case MICROSOFT_SIGN_UP_FAILED:
      return {
        ...state,
        isSignedUp: false,
        errorMessage: error,
      };
    case GOOGLE_MERGE_SUCCESS:
      return {
        ...state,
        isAccountMerged: true,
      };
    case GOOGLE_MERGE_FAILED:
      return {
        ...state,
        isAccountMerged: false,
        errorMessage: error,
      };
    case DISMISS_ERROR:
      return {
        ...state,
        errorMessage: '',
        showMergeOffer: false,
      };
    default:
      return state;
  }
}
