import { Box, Button, Container, Divider, Grid, Link, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { useLoginSSO } from '../../services/api';
import ErrorDialog from '../../ui/ErrorDialog';

const styles = {
  paper: (theme) => ({
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  submit: (theme) => ({
    margin: theme.spacing(3, 0, 2),
  }),
  titleText: (theme) => ({
    textAlign: 'left',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  }),
};

const LoginSSO = () => {
  const theme = useTheme();
  const [email, setEmail] = useState('');
  const [loginSSO, { error, isError }] = useLoginSSO();

  const onSubmit = (ev) => {
    ev.preventDefault();
    loginSSO(email)
      .unwrap()
      .then((data) => {
        const domain = data?.domain;
        window.location.href = `${process.env.REACT_APP_CUSTOMER_API_HOST}/sso/login/${domain}`;
      })
      .catch((err) => {});
  };

  return (
    <Container component="main" maxWidth="sm">
      <div style={styles.paper(theme)}>
        <Typography component="h1" variant="h1">
          Welcome Back
        </Typography>

        <form noValidate style={{ width: '100%' }}>
          <Box sx={styles.titleText}>
            <Typography variant="h2">Log In with SSO</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                onChange={(ev) => setEmail(ev.target.value)}
                name="email"
                autoComplete="email"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={styles.submit}
            onClick={onSubmit}>
            Login
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item className="drift-open-chat">
              {/* this button opens a drift conversation */}
              <Button color="primary" size="small" style={{ textTransform: 'none' }}>
                Chat with support
              </Button>
            </Grid>
          </Grid>

          <Box py={4}>
            <Divider />
          </Box>

          <Link href="/login" underline="hover">
            Log in using another method
          </Link>
        </form>
      </div>
      {isError && (
        <ErrorDialog title="Login with SSO Error" message={error?.data?.message ?? error?.message} />
      )}
    </Container>
  );
};

export default LoginSSO;
