import { Button, Container } from '@mui/material';
import React from 'react';
import { useGoogleLogin as useOAuthGoogleLogin } from '@react-oauth/google';
import LoggingManager from '../../logging';
import { ReactComponent as GoogleLogo } from '../../media/google.svg';
import { useGoogleLogin } from '../../services/api';
import ErrorDialog from '../../ui/ErrorDialog';
import { useTheme } from '@mui/material/styles';

const logger = new LoggingManager();

const handleGoogleError = (response) => {
  logger.notify(response.error, { context: 'Google Login' });
};

const GoogleSignIn = () => {
  const theme = useTheme();
  const styles = {
    sso: {
      marginTop: theme.spacing(2),
    },
    ssoLogo: {
      marginRight: theme.spacing(1),
    },
  };

  const [googleLogin, { data, error, isSuccess: isLoggedIn, isError }] = useGoogleLogin();
  const login = useOAuthGoogleLogin({
    onSuccess: (data) => googleLogin(data),
    onError: (response) => handleGoogleError(response),
    flow: 'auth-code',
  });

  if (isLoggedIn) {
    window.location.replace(`${process.env.REACT_APP_AURORA_HOST}/accelerate?token=${data?.token}`);
  }

  return (
    <Container component="div" maxWidth="xs">
      <div style={styles.sso}>
        <Button variant="outlined" style={{ width: '250px', color: 'black' }} onClick={() => login()}>
          <GoogleLogo style={styles.ssoLogo} /> Log In with Google
        </Button>
      </div>
      {isError && (
        <ErrorDialog
          title="Google Login Error"
          message={error?.data?.humanReadableMessage ?? 'Google login failed.'}
          showMergeOffer={error?.data?.showMergeOffer}
        />
      )}
    </Container>
  );
};

export default GoogleSignIn;
