import { api as apiInstance } from '../api';
import LoggingManager from '../logging';

const logger = new LoggingManager();

const apiBaseQuery =
  () =>
  async ({ url, method, data, context }) => {
    try {
      const result = await apiInstance({ url, method, data });
      return { data: result.data };
    } catch (err) {
      if (err.response?.status >= 500) {
        logger.notify(err, { context });
      }
      return {
        error: { status: err.response?.status, data: err.response?.data },
      };
    }
  };

export default apiBaseQuery;
