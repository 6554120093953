import { useMsal } from '@azure/msal-react';
import { Button, Container } from '@mui/material';
import React from 'react';
import LoggingManager from '../../logging';
import { ReactComponent as MicrosoftLogo } from '../../media/microsoft.svg';
import { MICROSOFT_SSO_SCOPES } from '../../modules/constants';
import { useMicrosoftLogin } from '../../services/api';
import ErrorDialog from '../../ui/ErrorDialog';
import { useTheme } from '@mui/material/styles';

const logger = new LoggingManager();

const MicrosoftSignIn = () => {
  const theme = useTheme();
  const { instance } = useMsal();

  const [microsoftLogin, { data, error, isError, isSuccess: isLoggedIn }] = useMicrosoftLogin();

  const authHandler = async () => {
    try {
      const response = await instance.loginPopup({ scopes: MICROSOFT_SSO_SCOPES });
      await microsoftLogin(response);
    } catch (err) {
      logger.notify(err, { context: 'Microsoft Login' });
    }
  };

  if (!!isLoggedIn) {
    window.location.replace(`${process.env.REACT_APP_AURORA_HOST}/accelerate?token=${data?.token}`);
  }

  const styles = {
    sso: {
      marginTop: theme.spacing(2),
    },
    ssoLogo: {
      marginRight: theme.spacing(1),
    },
  };

  return (
    <Container component="div" maxWidth="xs">
      <div style={styles.sso}>
        <Button variant="outlined" style={{ width: '250px', color: 'black' }} onClick={authHandler}>
          <MicrosoftLogo style={styles.ssoLogo} /> Log In with Microsoft
        </Button>
      </div>
      {isError && (
        <ErrorDialog
          title="Microsoft Login Error"
          message={error?.data?.message ?? 'Microsoft login failed.'}
        />
      )}
    </Container>
  );
};

export default MicrosoftSignIn;
