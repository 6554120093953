import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useLogin, useStreamlinedLogin } from '../../services/api';
import ErrorDialog from '../../ui/ErrorDialog';

const styles = {
  container: (theme) => ({
    textAlign: 'left',
  }),
  titleText: (theme) => ({
    textAlign: 'left',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  }),
  passwordInfo: (theme) => ({
    paddingLeft: '2px',
  }),
};

const LoginForm = () => {
  const { search } = useLocation();

  const [login, { error, isError }] = useLogin();
  const [streamlinedLogin] = useStreamlinedLogin();

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const queryParams = queryString.parse(search);

    const loginCode = queryParams.code;
    if (loginCode) {
      streamlinedLogin({ code: loginCode })
        .unwrap()
        .then((res) => {
          window.location.replace(`${process.env.REACT_APP_AURORA_HOST}/accelerate?token=${res.token}`);
        })
        .catch((err) => {});
    }
  }, [search, streamlinedLogin]);

  const setValue = (ev) => {
    const value = ev.target.type === 'checkbox' ? ev.target.checked : ev.target.value;
    const name = ev.target.name;

    switch (name) {
      case 'email':
        setEmail(value);
        break;
      case 'password':
        setPassword(value);
        break;
      default:
        break;
    }
  };

  const onSubmit = (ev) => {
    ev.preventDefault();
    const formData = { email, password };
    login(formData)
      .unwrap()
      .then((res) => {
        window.location.replace(`${process.env.REACT_APP_AURORA_HOST}/accelerate?token=${res.token}`);
      })
      .catch((err) => {});
  };

  const handleClickShowPassword = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  const handleMouseDownPassword = (ev) => {
    ev.preventDefault();
  };

  return (
    <form noValidate style={{ width: '100%' }}>
      <Box sx={styles.titleText}>
        <Typography variant="h2">Log In</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="email"
            label="Email Address"
            onChange={setValue}
            name="email"
            autoComplete="email"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            required
            fullWidth
            onChange={setValue}
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Box py={2}>
        <Button type="submit" fullWidth variant="contained" color="primary" onClick={onSubmit}>
          Login
        </Button>
      </Box>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Link href="/forgot-password" variant="body2" underline="hover">
            Forgot your password?
          </Link>
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end">
        <Grid item className="drift-open-chat">
          {/* this button opens a drift conversation */}
          <Button color="primary" size="small" style={{ textTransform: 'none' }}>
            Chat with support
          </Button>
        </Grid>
      </Grid>
      {isError && (
        <ErrorDialog
          title="Login Error"
          message={error?.data?.humanReadableMessage ?? 'Login Failed'}
          showMergeOffer={false}
        />
      )}
    </form>
  );
};

export default LoginForm;
