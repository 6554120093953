import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useForgotPassword } from '../../services/api';
import ErrorDialog from '../../ui/ErrorDialog';

const styles = {
  form: (theme) => ({
    width: '80%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  }),
  submit: (theme) => ({
    margin: theme.spacing(2, 0),
  }),
  buttonProgress: (theme) => ({
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }),
};

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState('');

  const [forgotPassword, { isError, error, isSuccess, isLoading }] = useForgotPassword();

  function onSubmit(ev) {
    ev.preventDefault();
    forgotPassword({ email });
  }

  function handleEmailInput(ev) {
    setEmail(ev.target.value);
  }

  return (
    <Box sx={styles.form}>
      <form noValidate>
        {!isSuccess && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                type="email"
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                onChange={handleEmailInput}
                name="email"
                autoComplete="email"
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        )}
        {!isSuccess && (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={isSuccess ? styles.buttonSuccess : styles.submit}
            onClick={onSubmit}
            disabled={isLoading}>
            Reset Password
          </Button>
        )}
        {isLoading && <CircularProgress size={24} sx={styles.buttonProgress} />}
        {isSuccess && (
          <Grid container sx={{ pb: 4 }}>
            <Grid item>
              <Typography component="p">
                {`An email has been sent to ${email} with a link to create a new password.`}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Link href="/login" variant="body2" underline="hover">
              Go to login page
            </Link>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Grid item className="drift-open-chat">
            {/* this button opens a drift conversation */}
            <Button color="primary" size="small" style={{ textTransform: 'none' }}>
              Chat with support
            </Button>
          </Grid>
        </Grid>
        {isError && (
          <ErrorDialog title="Reset Password Request Error" message={error?.data?.humanReadableMessage} />
        )}
      </form>
    </Box>
  );
};

export default ForgotPasswordForm;
