import { REQUEST_START, REQUEST_COMPLETE } from '../actions/types';

const defaultState = {
  isLoadingData: false
};

export default function requestReducer(state = defaultState, action) {
  switch (action.type) {
    case REQUEST_START:
      return {
        ...state,
        isLoadingData: true
      };
    case REQUEST_COMPLETE:
      return {
        ...state,
        isLoadingData: false
      };
    default:
      return state;
  }
}
