import * as React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';

import { dismissError } from '../actions';

const styles = {
  title: (theme) => ({
    color: 'red',
  }),
  error: (theme) => ({}),
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ErrorDialog = (props) => {
  const {
    title = 'Error',
    message = 'Sorry, something went wrong',
    details,
    callback,
    showMergeOffer = false,
  } = props;
  const [open, setOpen] = React.useState(true);

  const navigate = useNavigate();

  let isErrorDetails = false;
  if (!!details && !!details.length) {
    isErrorDetails = true;
  }

  const handleClose = () => {
    props.dismissError();
    !!callback && callback();
    setOpen(false);
  };

  const onSupportClick = () => {
    window.drift.api.openChat();
    handleClose();
  };

  const onMergeClick = () => {
    handleClose();
    navigate('/merge');
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle sx={styles.title}>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" aria-describedby="alert-dialog-slide-detail">
            {message}
          </DialogContentText>
          {isErrorDetails && (
            <Grid container direction="column">
              {details.map((detailMessage) => (
                <Grid item key={detailMessage}>
                  <Typography>{detailMessage}</Typography>
                </Grid>
              ))}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          {!!showMergeOffer && (
            <Button onClick={onMergeClick} color="primary">
              Merge Account
            </Button>
          )}
          <Button onClick={onSupportClick} color="primary">
            Chat with Sales or Support
          </Button>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = ({ user: { errorMessage = '' } }) => {
  return {
    errorMessage,
  };
};

export default connect(mapStateToProps, {
  dismissError,
})(ErrorDialog);
