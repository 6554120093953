import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { ThemeProvider } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { GoogleOAuthProvider } from '@react-oauth/google';
import cx from 'classnames';
import React from 'react';
import { Provider } from 'react-redux';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { AnalyticsProvider } from 'use-analytics';
import './App.scss';
import AppContainer from './AppContainer';
import ForgotPassword from './ForgotPassword';
import GoogleMerge from './GoogleMerge';
import Login from './Login';
import MergeInterstitial from './MergeSuccess';
import ResetPassword from './ResetPassword';
import SignUp from './SignUp';
import analytics from './analytics';
import AcceptInvite from './components/AcceptInvite';
import LoginSSO from './components/LoginSSO';
import OAuthAuthorize from './components/OAuthAuthorize';
import whiteLogo from './media/wevo-white-logo.png';
import store from './store';
import theme from './theme';

const pca = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: '/login/microsoft/callback',
  },
});

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY || 'localhost',
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_BUGSNAG_RELEASE_STAGE,
  apptype: process.env.REACT_APP_BUGSNAG_APP_VERSION,
});
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const styles = {
  root: {
    flexGrow: 1,
  },
  logo: {
    height: '50%',
    width: '160px',
  },
};

function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

function AppRoot() {
  return (
    <div className={cx('App', styles.root)}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <ElevationScroll>
          <AppBar position="static">
            <Toolbar>
              <a href="/">
                <img src={whiteLogo} style={styles.logo} alt="WEVO Logo" />
              </a>
            </Toolbar>
          </AppBar>
        </ElevationScroll>

        <Routes>
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/login/sso" element={<LoginSSO />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:resetToken" element={<ResetPassword />} />
          <Route path="/merge" element={<GoogleMerge />} />
          <Route path="/merge/success" element={<MergeInterstitial />} />
          <Route path="/sso/authorize" element={<OAuthAuthorize />} />
          <Route path="/accept-invite/:token" element={<AcceptInvite />} />
          {/* if there's no match, redirect to login */}
          <Route path="*" element={<Navigate replace to="/login" />} />
        </Routes>
      </GoogleOAuthProvider>
    </div>
  );
}

function App(props) {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <AnalyticsProvider instance={analytics}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <MsalProvider instance={pca}>
              <Router>
                <AppContainer>
                  <AppRoot {...props} />
                </AppContainer>
              </Router>
            </MsalProvider>
          </ThemeProvider>
        </AnalyticsProvider>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
