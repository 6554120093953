import { useMsal } from '@azure/msal-react';
import { Button, Container } from '@mui/material';
import React, { useEffect } from 'react';
import LoggingManager from '../../logging';
import { ReactComponent as MicrosoftLogo } from '../../media/microsoft.svg';
import { MICROSOFT_SSO_SCOPES } from '../../modules/constants';
import { useMicrosoftSignUp } from '../../services/api';
import ErrorDialog from '../../ui/ErrorDialog';
import { useAnalytics } from 'use-analytics';
import { TrackEvent } from '../../analytics';
import { useTheme } from '@mui/material/styles';

const logger = new LoggingManager();

const styles = {
  sso: (theme) => ({
    marginTop: theme.spacing(2),
  }),
  ssoLogo: (theme) => ({
    marginRight: theme.spacing(1),
  }),
};

const MicrosoftSignUp = () => {
  const theme = useTheme();
  const { instance } = useMsal();
  const { identify, track } = useAnalytics();

  const [microsoftSignUp, { data, error, isError, isSuccess: isSignedUp }] = useMicrosoftSignUp();

  useEffect(() => {
    if (isSignedUp && data?.token) {
      window.analytics.alias(data?.user_id, async () => {
        await identify(data?.user_id, {
          firstName: data?.fname,
          lastName: data?.lname,
          email: data?.email,
        });

        await track(TrackEvent.USER_SIGNED_UP, {
          isSSO: true,
          type: 'microsoft',
        });

        // redirecting the user must happen in the callback to give time for
        // the identify and track events to be called
        window.location.replace(`${process.env.REACT_APP_AURORA_HOST}/accelerate?token=${data?.token}`);
      });
    }
  }, [
    data?.email,
    data?.fname,
    data?.lname,
    data?.team_id,
    data?.token,
    data?.user_id,
    isSignedUp,
    identify,
    track,
  ]);

  const authHandler = async () => {
    try {
      const response = await instance.loginPopup({ scopes: MICROSOFT_SSO_SCOPES });
      await microsoftSignUp(response);
    } catch (err) {
      logger.notify(err, { context: 'Microsoft Signup' });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <div style={styles.sso(theme)}>
        <Button variant="outlined" style={{ width: '260px' }} onClick={() => authHandler()}>
          <MicrosoftLogo style={styles.ssoLogo(theme)} /> Sign Up with Microsoft
        </Button>
      </div>
      {isError && (
        <ErrorDialog
          title="Microsoft Sign-Up Error"
          message={error?.data?.message ?? 'Microsoft sign-up failed.'}
        />
      )}
    </Container>
  );
};

export default MicrosoftSignUp;
